import { Component, AfterViewInit, HostListener, OnDestroy, Input, DebugElement } from '@angular/core';
import { UnityInterfaceService } from 'src/app/Services/unity-interface.service';
import { WebInterfaceService } from 'src/app/Services/web-interface.service';

declare var createUnityInstance;
declare var WebInterface;

class SuperPad implements Gamepad
{
  public readonly axes = [-0.013473689556121826, 0.0027313828468322754, -0.0006256103515625, -0.0158846378326416];
  public readonly  buttons = [{
    pressed: false,
    touched: false,
    value: 0
  }];
  public readonly  connected = true;
  public readonly  hapticActuators = null;
  public readonly  id = 'Xbox 360 Controller (XInput STANDARD GAMEPAD)';
  public readonly  index = 0;
  public readonly  mapping: "standard";
  public readonly  timestamp = 2481.050000002142;
  public readonly  hand = "";
  public readonly pose = null;
}

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements AfterViewInit, OnDestroy
{
  public readonly componentName: string = 'Game';

  @Input()
  isMobile: boolean = false;

  gameWidth: number = 800;
  gameHeight: number = 450;

  mobileMargin: number = 0;

  constructor (
    private unityInterface: UnityInterfaceService,
    private webInterface: WebInterfaceService
  )
  {
    WebInterface = this.webInterface;
  }

  @HostListener('window:resize', ['$event'])
  onResize (event)
  {
    this.setGameClientDimensions(this.isMobile);
  }

  ngAfterViewInit ()
  {
    this.setGameClientDimensions(this.isMobile);
    createUnityInstance(document.querySelector("#unity-canvas"), {
      dataUrl: "Build/WebGL Build.data",
      frameworkUrl: "Build/WebGL Build.framework.js",
      codeUrl: "Build/WebGL Build.wasm",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "GodTheKid",
      productName: "Soulbound Online",
      productVersion: "0.1",
    }).then((unityInstance) => {
      this.unityInterface.UnityInstance = unityInstance;
    })
  }

  ngOnDestroy ()
  {
    this.unityInterface.UnityInstance.Quit(() =>
    {
      // console.log('**** Destroyed! ****');
    });
  }

  public setGameClientDimensions (isMobile: boolean)
  {
    this.isMobile = isMobile;
    let maxWidth = (window.innerWidth - 64); // padding
    let maxHeight = (window.innerHeight - 44 - 64); // header and padding

    if (this.isMobile)
    {
      maxWidth = (window.outerWidth > 400 && window.outerWidth < 500) ? 400 : window.outerWidth;
      this.mobileMargin = (window.outerWidth - maxWidth) / 2.0;
      maxHeight = (window.outerHeight - 44 - (this.mobileMargin * 2)); // header and padding
    }

    // Avoid changes that go unrendered
    setTimeout(() =>
    {
      if (maxWidth * 0.5625 <= maxHeight)
      {
        this.gameWidth = maxWidth;
        this.gameHeight = maxWidth * 0.5625; // 9/16
      }
      else
      {
        this.gameHeight = maxHeight;
        this.gameWidth = maxHeight * 1.777;
      }
    }, 0);
  }
}