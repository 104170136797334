import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css', '../page-styles.css']
})
export class UpdatesComponent implements OnInit
{

  constructor () { }

  ngOnInit () { }

}
