import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './PageComponents/home/home.component';
import { AboutComponent } from './PageComponents/about/about.component';
import { GameComponent } from './PageComponents/game/game.component';
import { UpdatesComponent } from './PageComponents/updates/updates.component';
import { PageNotFoundComponent } from './PageComponents/utility/page-not-found.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'game', component : GameComponent},
  { path: 'about', component:  AboutComponent},
  { path: 'updates', component: UpdatesComponent },
  { path: '', redirectTo: '/game', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
