import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnityInterfaceService
{
  public UnityInstance : any;

  constructor() { }

  public TestUnityInterface ()
  {
    console.log("************************************************************************");
    console.log("Attempting to interface with unity");
    console.log("************************************************************************");
    
    if (this.UnityInstance.SendMessage('JavascriptInterface', 'ReturnTrue'))
    {
      console.log("true was returned");
    }
    if (!this.UnityInstance.SendMessage('JavascriptInterface', 'ReturnFalse', 4))
    {
      console.log("false was returned");
    }
    this.UnityInstance.SendMessage('JavascriptInterface', 'PrintString', 'Print this beyotch!');
  }
}
