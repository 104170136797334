import { Injectable } from '@angular/core';
import { UnityInterfaceService } from 'src/app/Services/unity-interface.service';

@Injectable({
  providedIn: 'root'
})
export class WebInterfaceService {
  private isFullscreen : boolean = false;

  constructor(private unityInterface : UnityInterfaceService) { }

  public ToggleFullscreen ()
  {
    this.isFullscreen = !this.isFullscreen;
    this.unityInterface.UnityInstance.SetFullscreen(this.isFullscreen);
  }
}