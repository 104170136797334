import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule} from 'angularfire2';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './PageComponents/home/home.component';
import { GameComponent } from './PageComponents/game/game.component';
import { UpdatesComponent } from './PageComponents/updates/updates.component';
import { AboutComponent } from './PageComponents/about/about.component';
import { NavbarComponent } from './UtilityComponents/navbar/navbar.component';

// Services
import { UnityInterfaceService } from 'src/app/Services/unity-interface.service';
import { WebInterfaceService } from 'src/app/Services/web-interface.service';
import { PageNotFoundComponent } from './PageComponents/utility/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GameComponent,
    UpdatesComponent,
    AboutComponent,
    NavbarComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [UnityInterfaceService, WebInterfaceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
